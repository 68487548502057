.home-aboutus {
    background: map-deep-get($colors, "brand", "primary");
    margin: -3em 0 -5rem;

    &__inner {
        padding: 3rem 0 8rem;
        text-align: center;
        color: map-deep-get($colors, "white", "default");
    }

    &__content {
        font-size: rem-calc(18);
    }
}
