.text-link {
    font-family: $font-family-base;
    font-weight: 900;
    color: map-deep-get($colors, "brand", "secondary");

    &:hover,
    &:focus {
        text-decoration: underline;
        color: map-deep-get($colors, "brand", "secondary");
    }

    &--icon-right {
        svg {
            margin-right: 0;
            margin-left: .5em;
        }
    }

    svg {
        width: 20px;
        height: 20px;
        margin-right: .5em;
        transition: 0.3s ease-out;
    }

    svg path {
        fill: map-deep-get($colors, "brand", "secondary");
    }

    &--white {
        color: map-deep-get($colors, "white", "default");

        &:hover {
            color: map-deep-get($colors, "white", "default");
            text-decoration: none;

            svg {
                transform: translateX(0.25rem);
            }
        }

        svg path {
            fill: map-deep-get($colors, "white", "default");
        }
    }
}