$hamburger-padding-x                       : 15px;
$hamburger-padding-y                       : 5px;
$hamburger-layer-width                     : 20px;
$hamburger-layer-height                    : 2px;
$hamburger-layer-spacing                   : 4px;
$hamburger-layer-color                     : map-deep-get($colors, "white", "secondary");
$hamburger-layer-border-radius             : 0;
$hamburger-hover-opacity                   : .7;
$hamburger-hover-transition-duration       : .15s;
$hamburger-hover-transition-timing-function: linear;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
//

$hamburger-hover-use-filter: false;
$hamburger-hover-filter    : opacity(50%);
$hamburger-types: (squeeze);

@import "hamburgers/_sass/hamburgers/hamburgers";

.hamburger {
    z-index: 11;
    .hamburger-inner {
        box-shadow: $box-shadow-normal;
        background-color: map-deep-get($colors, "white", "default");

        &::after, &::before {
            box-shadow: $box-shadow-normal;
            background-color: map-deep-get($colors, "white", "default");
        }
    }

    &--inverted {
        .hamburger-inner {
            box-shadow: $box-shadow-normal;
            background-color: map-deep-get($colors, "white", "default");

            &::after, &::before {
                box-shadow: $box-shadow-normal;
                background-color: map-deep-get($colors, "white", "default");
            }
        }
    }

    &.is-active,
    &--scrolled {
        .hamburger-inner {
            box-shadow: none;
            background-color: map-deep-get($colors, "white", "default");

            &::after, &::before {
                box-shadow: none;
                background-color: map-deep-get($colors, "white", "default");
            }
        }
    }
}