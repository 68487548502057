.page-cta {
    margin-top: 2em;
    padding: 32px;
    border-radius: $border-radius;
    box-shadow: $box-shadow-medium;
    background: map-deep-get($colors, "brand", "primary");
    color: map-deep-get($colors, "black", "default");

    @include media-breakpoint-up(md) {
        margin-top: -5em;
    }

    &__title {
        margin-top: 0;
        margin-bottom: 1.25rem;
        color: map-deep-get($colors, "white", "default");
        font-family: $font-family-base;
        font-weight: 700;
        text-transform: none;
    }

    &__text {
        font-weight: 400;
    }

    /* stylelint-disable */
    p:last-of-type {
        margin-bottom: 0;
    }
    /* stylelint-enable */
}