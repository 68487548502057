﻿h1, h2, h3, h4 {
    margin: 0;
    font-family: $font-family-header;
    font-weight: 400;
    text-transform: uppercase;
}

h5, h6 {
    font-family: $font-family-base;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
    &--cursive {
        font-family: $font-family-header;
        font-weight: 400;
        font-style: italic;
    }
}

p {
    margin-top: 0;
    line-height: 1.5em;
}

.intro {
    font-size: 1.125rem;
    font-weight: 600;
}

h1, .h1 {
    font-size: rem-calc(40);
    line-height: 1em;
    margin-bottom: 0.5em;
    margin-top: .75em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(50);
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(60);
    }
}

h2, .h2 {
    font-size: rem-calc(36);
    line-height: 1em;
    margin-bottom: 0.5em;
    margin-top: 1.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(48);
    }
}

h3, .h3 {
    font-size: rem-calc(32);
    line-height: 1em;
    margin-bottom: 0.5em;
    margin-top: 1.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(40);
    }
}

h4, .h4 {
    font-size: rem-calc(28);
    line-height: 1.2em;
    margin-bottom: 0.5em;
    margin-top: 1.5em;

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(32);
    }
}

h5, .h5 {
    font-size: rem-calc(24);
    line-height: 1em;
    margin-bottom: 0.5em;
    margin-top: 1.5em;
}

h6, .h6 {
    font-size: rem-calc(18);
    line-height: 1em;
    margin-bottom: 0.5em;
    margin-top: 1.5em;
}

strong {
    font-weight: 900;
}
