.page-intro {
    &__link {
        display: inline-block;
        margin-bottom: 1em;
    }

    &__title {
        margin-top: 2rem;
        color: map-deep-get($colors, "brand", "primary");
    }

    &__subtitle {
        color: map-deep-get($colors, "brand", "secondary");
    }
}