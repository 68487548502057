.services-card {
    background: map-deep-get($colors, "gray", "medium");
    padding: 4.5rem 5rem;
    margin-bottom: 1.25rem;
    height: calc(100% - 1.25rem);

    @include media-breakpoint-down(sm){
        padding: 2rem;
        margin-bottom: 0.625rem;
        height: calc(100% - 0.625rem);
    }

    &__title {
        margin: 0 0 1.5rem;
    }

    &__text {
        /* stylelint-disable */
        p:last-of-type {
            margin-bottom: 0;
        }
        /* stylelint-enable */
    }
}