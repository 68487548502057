.home-intro {
    overflow: hidden;
    margin-top: 2.5rem;

    &__title {
        color: map-deep-get($colors, "white", "default");
        margin-top: 1.5em;
    }

    &__text {
        padding-bottom: 5rem;
    }

    &__content {
        font-size: 18px;
    }

    &__button {
        margin-top: 2em;
        margin-bottom: 2em;
    }
}