.project-card {
    margin-bottom: 1.5em;
    transition: $transition-default;
    $elm: &;

    &__inner {
        position: relative;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        padding: 1.25rem;
        height: 15rem;
        border-radius: $border-radius;
        background-color: map-deep-get($colors, "brand", "primary");
        background-size: cover;
        background-position: center;

        @include media-breakpoint-up(md) {
            height: 26rem;
        }

        &:hover,
        &:focus {
            text-decoration: none;
            box-shadow: $box-shadow-medium;

            #{$elm}__link {
                margin-top: 1em;
                height: 2em;
            }

            #{$elm}__inner {
                text-decoration: none;
            }

            #{$elm}__color-overlay {
                opacity: 1;
            }
        }
    }

    &__color-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: linear-gradient(33.69deg, map-deep-get($colors, "brand", "primary") 0%, rgba(map-deep-get($colors, "brand", "primary"), 0.2) 100%);
        transition: $transition-default;
    }

    &__content {
        position: relative;
        z-index: 1;
    }

    &__title {
        margin: 0;
        color: map-deep-get($colors, "white", "default");
        font-size: 1.5rem;
        font-family: $font-family-base;
        font-weight: 700;
        text-transform: none;

        @include media-breakpoint-only(md) {
            font-size: inherit;
        }
    }

    &__link {
        display: block;
        overflow: hidden;
        margin-top: 1em;
        height: 1.2em;
        transition: $transition-default;
        color: map-deep-get($colors, "white", "default");
        font-weight: 700;
        font-size: 1rem;

        svg path {
            fill: map-deep-get($colors, "white", "default");
        }

        @include media-breakpoint-up(md) {
            margin-top: 0;
            height: 0;
        }
    }
}
