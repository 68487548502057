.home-examples {
    &__title {
        color: map-deep-get($colors, "white", "default");
    }

    &__content {
        font-size: 18px;
        margin-bottom: 3em;
    }

    &__overview-link {
        float: right;
        color: map-deep-get($colors, "white", "default");
    }
}