.page-header {
    position: relative;
    margin-top: #{-20px - map_get($header-height, "small")};
    height: 250px;

    @include media-breakpoint-up(md) {
        margin-top: #{-20px - map_get($header-height, "medium")};
        height: 500px;
    }

    @include media-breakpoint-up(lg) {
        margin-top: #{-20px - map_get($header-height, "large")};
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 200px;
        background: linear-gradient(180deg, rgba(map-deep-get($colors, "brand", "secondary"), 0.6) 0%, rgba(map-deep-get($colors, "brand", "secondary"), 0) 100%);
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    &__surface-shape {
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        background-size: cover;
        background-position: center;

        svg {
            width: 100%;
            height: auto;
        }
    }
}