// Rich text styling layout
/* stylelint-disable */
.rich-text-area {
    -webkit-font-smoothing: antialiased;
    line-height: 1.937em;

    //h2, h3, h4 ,h5, h6 {
    //    color: map-deep-get($colors, "brand", "secondary");
    //}

    h2 {
        font-size: rem-calc(28);

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(32);
        }
    }

    h3 {
        font-size: rem-calc(24);
    }

    img {
        max-width: 100%;
        border-radius: $border-radius;
        box-shadow: $box-shadow-medium;
        margin-bottom: 2em;

        @include media-breakpoint-up(lg) {
            max-width: 116.66%;
            width: 116.66%;
        }
    }

    p {
        margin-bottom: 2em;
    }

    a:not(.button) {
        text-decoration: underline;
    }

    ul {
        margin: rem-calc(20) 0;

        li {
            position: relative;
            padding-left: 20px;
            margin-bottom: 0;

            &::before {
                content: "";
                background: map-deep-get($colors, "brand", "primary");
                width: 0.5em;
                height: 0.5em;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: .65em;
            }
        }

        ul {
            margin: 0;
        }
    }
}

/* stylelint-enable */
