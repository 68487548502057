.header {
    // z-index is om te zorgen dat deze boven de barba laag zit. Hierdoor wordt voor het oog alleen de content vervangen
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    height: map_get($header-height, "small");
    transition: $transition-default;
    background: map-deep-get($colors, "black", "default");
    $header: &;

    @include media-breakpoint-up(md) {
        margin: 0 auto;
        height: map_get($header-height, "medium");
        overflow: visible;
    }

    @include media-breakpoint-up(xl) {
        height: map_get($header-height, "large");
    }

    &--inverted {
        background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);

        #{$header}__logo {
            /* stylelint-disable */
            svg #dark path {
                fill: map-deep-get($colors, "white", "default");
            }
            /* stylelint-enable */

            svg {
                filter: drop-shadow($box-shadow-normal);
            }
        }

        #{$header}__button {
            box-shadow: $box-shadow-normal;
        }
    }

    &--scrolled {
        background: map-deep-get($colors, "black", "default");
        margin-top: 0;

        @include media-breakpoint-up(md) {
            height: map_get($header-height, "medium--scrolled");
        }

        #{$header}__button {
            box-shadow: none;
        }
    }

    &--expanded {
        background: map-deep-get($colors, "black", "default");
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
    }

    &__logo {
        position: relative;
        transition: $transition-default;
        display: flex;
        width: 5rem;
        height: 80px;

        @include media-breakpoint-up(md) {
            padding-top: 0;
            padding-bottom: 0;
            width: 9.5rem;
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            transition: $transition-default;

            /* stylelint-disable */
            circle {
                fill: transparent;

                @at-root .header--scrolled & {
                    fill: map-deep-get($colors, "black", "default");
                }
            }
            /* stylelint-enable */

            @include media-breakpoint-up(md) {
                width: auto;
                height: 180%;
            }
        }
    }

    &--scrolled,
    &--expanded {
        #{$header}__logo {
            height: 60px;
        }
    }

    &__menu-toggle {
        float: right;
        position: relative;
    }
}